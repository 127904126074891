<template>
  <base-section id="privacy-policy">
    <base-section-heading title="Privacy policy">
    </base-section-heading>
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col style="padding: 16px;">
<p>This policy describes how Tentifly OÜ collects, uses and discloses information, and what choices you have with respect to the information.</p>
<h1>Applicability</h1>
<p>This Privacy Policy applies to Tentifly OÜ’s “Tentifly” software, including the associated forms, plugins, online store space and it's other sales and solutions provided in relation with “Tentifly” software (collectively, the “Services”), Tentifly.com and other Tentifly OÜ websites (collectively, the “Websites”) and other interactions with Tentifly OÜ (e.g., customer inquiries, training, etc.). If you do not agree with the terms, do not access or use the Services, Websites or any other aspect of Tentifly OÜ’s business.
</p><p>This policy does not apply to any third party applications, software, products, services, businesses or interactions related to or that integrate with the Services (“Third Party Services”). Furthermore, a separate agreement governs the delivery, access and use of the Services (the “Customer Agreement”) or other content submitted through Services (collectively, “Customer Data”). The entity (e.g., you, your employer or another entity or person) that entered into the Customer Agreement (“Customer”) controls their instance of the Services (their “Workspace”) and any associated Customer Data.
</p><h1>Information We Collect And Receive</h1>
<p>Tentifly OÜ may collect and receive Customer Data, other information and data (collectively, “Other Information”) in a variety of ways. When you use our Website or Services in any way, we may collect and process about you:
<ul><li>
Customer Data. Customers or individuals granted access to a Workspace by a Customer (“Authorized Users”) routinely submit Customer Data to Tentifly OÜ when using the Services. Such information can vary and may include your customers, Workspace visitors or employees personal data such as:
</li>
<ul>
  <li>Identity Data including first and last name, identification code and workplace.
  </li>
  <li>Contact Data including email address and telephone numbers.
  </li>
  <li>Professional Data including your authorization level, job title, company information, and other management and sales related data and goals.
  </li>
  <li>Transaction Data including details about sales documents (orders, invoices) to and from you and other details of products and services you have purchased from us.
  </li>
  <li>Marketing and Communications Data including your preferences in receiving marketing from us and our third parties and communication preferences with your customers or any other entity you engage using Services.
  </li>
  <li>Custom information which the Authorized User can add to the person or related documents via customized fields, notes or attached files.
  </li>
</ul>
<li>Other Information. Tentifly OÜ also collects, generates and/or receives Other Information:
</li>
  <ul>
    <li>Technical Data includes internet protocol (IP) address, time zone settings, location, Services extension (“Add-ons”), and other technology you use to access Services or Websites.
  </li>
  <li>Profile Data includes your purchases and/or orders, preferences, feedback, communications and survey responses, and login credentials.
  </li>
  <li>Custom information which can vary depending on extra developed functionalities and active Add-ons.
  </li>
</ul>
<li>Cookie Information. Tentifly OÜ uses cookies and similar technologies in our Websites and Services that help us collect, store and provide Other Information. The Websites and Services may also include cookies and similar tracking technologies of third parties, which may collect Other Information about you via the Websites and Services and across other websites and online services.
</li><li>Third Party Data. Tentifly OÜ may receive data about organizations, industries, Website visitors, marketing campaigns and other matters related to our business from affiliates, our partners or others that we use to make our own information better or more useful. This data may be combined with Other Information we collect and might include aggregate level data.
</li><li>Additional Information Provided to Tentifly OÜ. We receive Other Information when submitted to our Websites or if you participate in a focus group, contest, activity or event, apply for a job, request support, interact with our social media accounts or otherwise communicate with Tentifly OÜ.
</li></ul><p>Generally, no one is under an obligation to provide any Customer Data or Other Information (collectively, “Information”). However, certain Information is collected automatically. Specific information provided by Customer to Tentifly OÜ can vary depending on the agreed-upon contractual obligations of both parties and hence this list of data being processed can differ. Tentifly OÜ is not obligated to know about the data which the Customer inserts into the Tentifly OÜ Services. Customer is 100% owner of their data, hence Controller to their data. Customer is obligated to follow the current Data Protection Law.
</p><span style="font-weight: bold;">Links to Other Websites</span>
<p>Our Websites and Services may contain links to third-party websites. Those websites have their own privacy policies and we do not accept any responsibility or liability for those practices.
</p><span style="font-weight: bold;">Age Limitations</span>
<p>To the extent prohibited by applicable law, Tentifly OÜ does not allow the use of our Services and Websites by anyone younger than 16 years old. Insertion of Customer Data containing personal data of individuals younger than 16 years old is prohibited without parental or guardian lawful permission.
</p><h1>Use of The Information</h1>
<p>Customer Data is used in accordance with Customer’s instructions, terms in the Customer Agreement and Customer’s use of Services functionality, and as required by Data Protection Law or any other applicable law. Tentifly OÜ is a processor of Customer Data and Customer is the controller. Tentifly OÜ uses Other Information in furtherance of our legitimate interests in operating our Services, Websites, and business. More specifically, Tentifly OÜ uses Other Information:
</p>
<ul><li>To provide, update, maintain and protect our Services, Websites, and business. This includes the delivery of the Services under a Customer Agreement, prevention or addressing service errors, security or technical issues, monitoring and analyzing usage, trends and other activities or at an Authorized User’s request.
As required by applicable law, legal process or regulation.
</li><li>To communicate with you or by responding to your requests, comments, and questions.
</li><li>To develop or change features.
</li><li>To send emails and other communications. We may send you service, technical and other administrative emails, messages and other types of communications. We may also contact you about changes in our Services, related offerings and notices. These communications are considered part of the Services and you may not opt out of them.
</li><li>For billing, payments, account management, and other administrative matters.
</li><li>To investigate and help prevent security issues and abuse.
</li></ul>
<p>If Information is processed in a way that it is no longer reasonably associated with an identified or identifiable natural person, Tentifly OÜ may use it for any business purpose. To the extent Information is associated with an identified or identifiable natural person and is protected as personal data under applicable Data Protection Law, it is referred to in this Privacy Policy as “Personal Data.”
</p><h1>Data Retention</h1>
<p>Tentifly OÜ will retain Customer Data in accordance with a Customer’s instructions, including any applicable terms in the Customer Agreement and Customer’s use of Services functionality, and as required by applicable law. The deletion and use of the Services may result in the deletion and/or de-identification of any associated Other Information. Tentifly OÜ may retain Other Information for as long as necessary for the purposes described in this Privacy Policy. This may include keeping your Other Information for the period of time needed for Tentifly OÜ to pursue legitimate business interests, conduct audits, comply or demonstrate compliance with legal obligations, resolve disputes and enforce our agreements.
</p><h1>Information Sharing And Disclosing</h1>
<p>This section describes how Tentifly OÜ may share and disclose Information. Customers determine their own policies and practices for the sharing and disclosure of Information, and Tentifly OÜ does not control how they or any other third parties choose to share or disclose Information.</p>
<ul><li>Customer’s Instructions. This includes sharing and disclosing Customer Data in accordance with a Customer’s instructions, applicable terms in the Customer Agreement and Customer’s use of Services, and in compliance with applicable law and legal process.
</li><li>Displaying the Services. Other Information may be displayed to all Authorized Users after it has been submitted to Tentifly OÜ Services.
</li><li>Customer Access. Owners, Authorized Users and other Customer representatives and personnel may be able to access, modify or restrict access to Other Information.
</li><li>Third Party Service Providers and Partners. We may engage third parties as service providers or business partners to process Other Information.
</li><li>Third Party Services. Customer or its Authorized Users may enable Third Party Services. When enabled, Tentifly OÜ may share Other Information with Third Party Services. Third Party Services are not owned or controlled by Tentifly OÜ and third parties that have been granted access to Other Information may have their own policies and practices.
</li><li>During a Change to Tentifly OÜ’s Business. If Tentifly OÜ engages in a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all of Tentifly OÜ’s assets or stock, financing, public offering of securities, acquisition of all or a portion of our business, a similar transaction or proceeding, or steps in contemplation of such activities, some or all Other Information may be shared or transferred, subject to standard confidentiality arrangements.
</li><li>Aggregated or De-identified Data. We may disclose or use aggregated or de-identified Other Information for any purpose.
</li><li>To Comply with Laws. Upon receiving a request for information, we may disclose Other Information if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation or legal process.
</li><li>To enforce our rights, prevent fraud, and for safety. To protect and defend the rights, property or safety of Tentifly OÜ or third parties, including enforcing contracts or policies, or in connection with investigating and preventing fraud or security issues.
</li><li>With Consent. Tentifly OÜ may share Other Information with valid consent.
</li></ul>
<h1>Security</h1>
<p>Tentifly OÜ works hard to protect your Other Information from loss, misuse, and unauthorized access or disclosure. Tentifly OÜ transfers your data over secure protocols. The environment that hosts the Tentifly OÜ services maintains multiple certifications, including ISO 27001 compliance. Given the nature of communications and information processing technology, Tentifly OÜ cannot guarantee that Information, during transmission through the Internet or while stored on our systems or otherwise in our care, will be absolutely safe from intrusion by others.
</p><h1>Personal Data Storage Duration</h1>
<p>We store your personal data during the time that you are a Customer of the Tentifly OÜ. If you have consented to receive marketing inquiries or other information from Tentifly OÜ, we may store your data until you renounce any applicable consent. We reserve the right to store or delete your personal data earlier or later than set forth herein if required to do so by an applicable law or regulation, including the current Data Protection Law and for the exercise or defense of legal claims.
</p><h1>Your Rights</h1>
<p>Tentifly OÜ commits to ensure that you have control and transparency to your personal data and its processing. Below is a summary of your rights and additional commitments from Tentifly OÜ. You may exercise your rights by contacting us at info(@)Tentifly.com. Tentifly OÜ processes only requests from individuals directly associated to Tentifly OÜ Services, hence will only process requests about Personal Data for which Tentifly OÜ is controller party. It is your responsibility to ensure that any information you have provided to us is accurate and up-to-date.
</p><p>The right of Access. You can request a copy of the personal data we hold about you.
</p><p>Right to Erasure (‘Right to be Forgotten’). You have the right to request that your personal data be deleted in certain circumstances including:
<ul><li>Your personal data is no longer needed for the purpose for which they were initially collected;
</li><li>You withdraw provided consent for processing (Only applicable for processing where consent was required);
</li><li>You object to the processing and there are no overriding legitimate grounds justifying us processing the personal data;
</li><li>The personal data have been unlawfully processed; or
</li><li>To comply with a legal obligation.
</li></ul>
</p><p>Right to Restriction of Processing. You may ask us to restrict the use of your personal data in case:
<ul><li>The accuracy of the personal data is contested;
</li><li>The processing is unlawful but you do not want it erased;
</li><li>We no longer need the personal data but you require it for the establishment, exercise or defense of legal claims; or
</li></ul>You have objected to the processing and verification as to our overriding legitimate grounds is pending.
</p><p>We can continue to use your personal data following a request for restriction:
<ul><li>Where we have your consent to do so;
</li><li>For the establishment, exercise or defense of legal claims;
</li><li>To protect the rights of another; or
</li><li>For reasons of important public interest.
</li></ul></p><p>Right to Data Portability. Where you have provided personal data to us, you have a right to receive such personal data back in a structured, commonly-used and machine-readable format, and to have those data transmitted to a third-party data controller without hindrance but in each case only where:
<ul><li>The processing is carried out by automated means; and
</li><li>The processing is based on your consent or on the performance of a contract with you.
</li></ul></p><p>Right to Object. You have a right to object to the processing of your personal data in those cases where we are processing your personal data in reliance on our legitimate interests. In such a case we will stop processing your personal data unless we can demonstrate compelling legitimate interests which override your interests. You also have the right to object where we are processing your personal data for direct marketing purposes.
</p><p>Tentifly OÜ exercises two types of consents. First is required for us to provide Services to Customer and such consent can be only opted-out once Service Agreement and other obligations have ended. Second is explicitly opted-in marketing consent. If you wish to not receive further marketing communications, then simply follow the email unsubscribe process or directions provided at the bottom of the email.
</p><p>Automated Decision-Making. Tentifly OÜ has no automated decision-making functionalities.
</p><p>Right to Complain. You have the right to lodge a complaint with a supervisory authority, in particular in the Member State of your residence, place of work or place of an alleged infringement. We respond to your request in writing or orally during  one month after receipt of your request. That period may be extended by two further months where necessary, depending on the complexity and number of requests. We will require proof of identification to verify your request.
</p><h1>Data Controller And Processor</h1>
<p>Customer is the controller of Customer Data and Tentifly OÜ is the processor of Customer Data and the controller of Other Information.
</p><h1>Cookies</h1>
<p>Cookies are small text files sent by us to your computer or mobile device. They are unique to your account or your browser. Session-based cookies last only while your browser is open and are automatically deleted when you close your browser. Persistent cookies last until you or your browser delete them or until they expire. We may use both session-based and persistent cookies, of which some may be third-party cookies.
</p><span style="font-weight: bold;">How Tentifly OÜ uses cookies?</span>
<p>Tentifly OÜ uses cookies only to store privacy policy consent and language preferences.
<v-row style="font-weight: bold;">
  <v-col cols="4">Cookie Name</v-col><v-col cols="4">
Cookie Purpose
</v-col><v-col cols="4">
Expires</v-col>
</v-row><v-row>
  <v-col cols="4">
PRIVACY_CONSENT</v-col><v-col cols="4">
Used by us to remember consent to our privacy policy.</v-col><v-col cols="4">
1 year</v-col>
</v-row><v-row><v-col cols="4">
_lang</v-col><v-col cols="4">
Used by us to remember your language preference.</v-col><v-col cols="4">
1 year</v-col>
</v-row>
</p><span style="font-weight: bold;">Opting out from cookies</span>
<p>
Major browsers have cookie management functionalities. For popular browsers such information can be found at:
<ul><li>
<a href="https://support.google.com/chrome/answer/95647?hl=en-GB">Google Chrome</a>
<a href="https://support.microsoft.com/en-us/kb/260971">Internet Explorer</a></li><li>
<a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></li><li>
<a href="https://support.apple.com/kb/PH5042?locale=en_US">Safari (Desktop)</a></li><li>
<a href="https://support.apple.com/en-us/HT201265">Safari (Mobile)</a></li><li>
<a href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067">Android Browser</a></li><li>
<a href="http://www.opera.com/help">Opera</a></li><li>
<a href="http://www.opera.com/help/mobile/android#privacy">Opera Mobile</a></li></ul>
</p>
<p>For other browsers, please consult the support of your browser. Some cookies can be opted-out on third party sites. On mobile platforms see your device settings to control whether you see interest-based marketing ads.
</p><h1>Changes To This Privacy Policy</h1>
<p>Tentifly OÜ may change this Privacy Policy at any given time. Laws, regulations, industry standards or our business may change over time, thus may also require necessary changes to this Privacy Policy. If you disagree with the changes, you can end your Customer Agreement with Tentifly OÜ.
</p>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "Privacy-policy",

  data: () => ({
    
  })
};
</script>
